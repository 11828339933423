import { parseTime } from '@/utils'

const iconStyle = (value) => `width: 16px; height: 16px; display: inline-block;
                        background: var(${value === 1 ? '--color-primary' : '--color-success'});
                        margin-right: 10px; border-radius: 50%;`
const MSG = { 0: 'Normal', 1: 'Issues happening' }
export const headers = [
  {
    label: 'Date and Time Detected',
    prop: 'dataTimestamp',
    property: {
      width: '250px',
      sortable: 'custom'
    },
    formatter: (row) => {
      return `${parseTime(row.dataTimeStr, 'reg')} ${parseTime(row.dataTimeStr, '{h}:{i}')}`
    }
  },
  {
    label: 'Tilt Angle',
    prop: 'tilt',
    property: {
      width: '350px'
    },
    formatter: (row) =>
      `Rx ${Number(row.rx).toFixed(3)}°&emsp;
       Ry ${Number(row.ry).toFixed(3)}°&emsp;
       Rz ${Number(row.rz).toFixed(3)}°`
  },
  {
    label: 'Description',
    prop: 'overAngleDesc',
    formatter: (row) => row.overAngleDesc || 'N/A',
    property: {
      width: '600px'
    }
  },
  {
    label: '',
    prop: 'overAngleFlag',
    property: {
      minWidth: '150px'
    },
    render: (h, { row }) => {
      return h('div', { class: '_flex _flex-align__center' }, [
        h('i', { style: iconStyle(row.overAngleFlag) }),
        MSG[row.overAngleFlag]
      ])
    }
  }
]

export const formView = [
  {
    label: 'Sensor',
    prop: 'sensor',
    width: '50px'
  },
  {
    label: 'Date Range',
    prop: 'date',
    width: '90px'
  },
  {
    label: 'Time Range',
    prop: 'time',
    width: '90px'
  }
]

// components 文件夹下文件 props 属性
export const componentProp = {
  dateArr: {
    type: Array,
    default: () => []
  },
  scrollHeight: {
    type: Number,
    default: 300
  }
}

export const documentTabs = [
  {
    label: 'Drawings',
    type: 'drawings'
  },
  {
    label: 'Relevant maps',
    type: 'relevant-maps'
  },
  {
    label: 'Implementation Plan',
    type: 'implementation-plan'
  },
  {
    label: 'CAD Application & Permit',
    type: 'cad-application-and-Permit'
  },
  {
    label: 'Supplementary Documents',
    type: 'supplementary'
  }
]
